import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController, Platform, AlertController } from '@ionic/angular';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { DataService } from './services/data.service';
import Swal from 'sweetalert2';
import { NgZone } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';

// import { AppVersion } from '@ionic-native/app-version/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  closed$ = new Subject<any>();
  showTabs = true; // <-- show tabs by default
  navigate: any;
  info: any;
  uuid: any;
  appVerNo: any;
  resumeListener: any;
  
  constructor(
    private menu: MenuController,
    private _router: Router,
    private platform: Platform,
    private _location: Location,
    public alertController: AlertController,
    private dataService: DataService,
    private ngZone: NgZone
  ) {
    this.initializeApp();
  }
  ngOnInit() {
    this._router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      takeUntil(this.closed$)
    ).subscribe(event => {
      if (event['url'] === '/login' || event['url'] === '/device-validation' || event['url'] == '/forget-password') {
        this.showTabs = false; // <-- hide tabs on specific pages
      }else{
        this.showTabs = true;
      }
    });
  }
  
  ngOnDestroy() {
    this.closed$.next(); // <-- close subscription when component is destroyed
  }
  ionViewWillEnter() {
    
  }
  
  ionViewWillLeave() {
    
  }
  initializeApp() {
    this.platform.ready().then(() => {    
      SplashScreen.show({
        autoHide: false
      });  
      
      this.uuid = Device.getId();
      Device.getId().then(data => {
        this.dataService.deviceUUID = data.uuid;
      });
      Device.getInfo().then(data => {
        this.dataService.deviceModel = data.model;
      });
    });
    //npm i cordova-plugin-mock-gps-checker
    var whiteList = ['hrms.bigapollospectra.com']; 
    
    // console.log((<any>window).gpsmockchecker);
    // (<any>window).gpsmockchecker.check(whiteList, (result) => {
    //   if(result.isMock){
    //     this.dataService.customErrorAlert("Mock GPS in use, Exit App!");
    //     navigator['app'].exitApp();
    //   }
    //   else{
    //     console.log("All its ok");
    //   }
    // }, (error) => console.log(error));



    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('')) {
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {
        console.log('Navigate to back page');
        this._location.back();
      }
    });
    this.getAppVersion();

  }
  getAppVersion(){
    // alert(JSON.stringify(this.platform.platforms()[0]));
    this.dataService.GetDetailRequest('Setting/AppVersion?AppURL=com.bigapollospectra.hrms&Source='+this.platform.platforms()[0]).subscribe((data) => {
      const anyData = data as any;
      console.log(anyData);
      if(anyData.code == 200){
        if(Number(anyData.data) > this.dataService.appVersion){
          this.dataService.sweetUpdateApp();
        }
      }else{
        this.dataService.presentToast(anyData.message);
               
      }
    }, error => {
      this.dataService.customErrorAlert(JSON.stringify(error.error.message));
      
    });
  }
  navigateTo(url){
    if(url == '/login'){
      localStorage.removeItem('isValidDevice');
      localStorage.removeItem('token');
    }
    this.menu.toggle();
    this._router.navigateByUrl(url);
  }
  footerNavigateTo(url){
    // this.menu.toggle();
    this._router.navigateByUrl(url);
  }
  showExitConfirm() {
    Swal.fire({
      title: 'Exit App',
      text: "Are you sure to exit app?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007c9d',
      cancelButtonColor: '#d33',
      heightAuto: false,
      confirmButtonText: 'Yes, exit!'
    }).then((result) => {
      if (result.isConfirmed) {
        navigator['app'].exitApp();
      }
    })
    // this.alertController.create({
    //   header: 'Exit App',
    //   message: 'Do you want to close the app?',
    //   backdropDismiss: false,
    //   buttons: [{
    //     text: 'Stay',
    //     role: 'cancel',
    //     handler: () => {
    //       console.log('Application exit prevented!');
    //     }
    //   }, {
    //     text: 'Exit',
    //     handler: () => {
    //       navigator['app'].exitApp();
    //     }
    //   }]
    // })
    //   .then(alert => {
    //     alert.present();
    //   });
  }
}