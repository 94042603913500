import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, ToastController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  appVersion: number = 16;
  headers: any;
  domainName: string = 'hrms.bigapollospectra.com';
  apiUrl: string = 'https://betaapi.hrmsplus.in/api/';
  deviceModel: any;
  deviceUUID: any;

  constructor(
    private http: HttpClient,
    private alertController: AlertController,
    private toastController: ToastController,
    private router: Router
  ) { }

  sweetSuccess(msg){
    Swal.fire({
      title: 'Success!',
      text: msg,
      icon: 'success',
      heightAuto: false,
      confirmButtonColor: '#007c9d'
    });
  }

  sweetWarning(msg){
    Swal.fire({
      title: 'Warning',
      text: msg,
      icon: 'warning',
      heightAuto: false,
      confirmButtonColor: '#007c9d'
    });
  }

  sweetError(msg){
    Swal.fire({
      title: 'Error',
      text: msg,
      icon: 'error',
      heightAuto: false,
      confirmButtonColor: '#007c9d'
    });
  }

  sweetInfo(msg){
    Swal.fire({
      title: 'Info',
      text: msg,
      icon: 'info',
      heightAuto: false,
      confirmButtonColor: '#007c9d'
    });
  }

  sweetUpdateApp(){
    Swal.fire({
      title: 'Update App',
      text: "New Version available, update your app.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007c9d',
      cancelButtonColor: '#d33',
      heightAuto: false,
      confirmButtonText: 'Yes, update!'
    }).then((result) => {
      if (result.isConfirmed) {
        window.open('https://play.google.com/store/apps/details?id=com.bigapollospectra.hrms','_system');
        // Browser.open({url: 'https://play.google.com/store/apps/details?id=com.bigapollospectra.hrms'});
      }else{
        navigator['app'].exitApp();
      }
    })
  }

  sweetConfirm(msg){
    Swal.fire({
      title: 'Update App',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#007c9d',
      cancelButtonColor: '#d33',
      heightAuto: false,
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Browser.open({url: 'https://play.google.com/store/apps/details?id=com.bigapollospectra.hrms'});
      }else{
        navigator['app'].exitApp();
      }
    })
  }

  sweetUnauthorized(){
    Swal.fire({
      title: 'Unauthorized',
      text: "Unauthorized Access",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Exit App',
      confirmButtonColor: '#007c9d',
      cancelButtonColor: '#d33',
      heightAuto: false,
      confirmButtonText: 'Login'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        this.router.navigateByUrl('/login');
        // Browser.open({url: 'https://play.google.com/store/apps/details?id=com.bigapollospectra.hrms'});
      }else{
        navigator['app'].exitApp();
      }
    })
  }

  async successAlert() {
    const alert = await this.alertController.create({
      header: 'Success',
      subHeader: 'Record Submitted',
      message: 'Your record submitted successfully.',
      buttons: ['OK']
    });

    await alert.present();
  }

  async errorAlert() {
    const alert = await this.alertController.create({
      header: 'Error',
      subHeader: 'Network Error',
      message: 'Network error occur. Please try again.',
      buttons: ['OK']
    });

    await alert.present();
  }

  async customErrorAlert(msg) {
    const alert = await this.alertController.create({
      header: 'Error',
      subHeader: 'Error',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  async customAlert(msg) {
    const alert = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Required field',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      animated: true,
      cssClass: 'toast'
    });
    toast.present();
  }

  // async presentToastWithOptions() {
  //   const toast = await this.toastController.create({
  //     header: 'Toast header',
  //     message: 'Click to Close',
  //     icon: 'information-circle',
  //     position: 'top',
  //     buttons: [
  //       {
  //         side: 'start',
  //         icon: 'star',
  //         text: 'Favorite',
  //         handler: () => {
  //           console.log('Favorite clicked');
  //         }
  //       }, {
  //         text: 'Done',
  //         role: 'cancel',
  //         handler: () => {
  //           console.log('Cancel clicked');
  //         }
  //       }
  //     ]
  //   });
  //   await toast.present();

  //   const { role } = await toast.onDidDismiss();
  //   console.log('onDidDismiss resolved with role', role);
  // }


  GetHeaders(){
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Basic ' + localStorage.getItem('token'));
    this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    this.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    this.headers = this.headers.append('Accept', 'application/json');
    this.headers = this.headers.append('Content-Type', 'application/json');
  }
  GetDetailRequest(url){
    this.GetHeaders();
    const response = this.http.get(this.apiUrl + url, { headers: this.headers });
    return response;
  }
  GetSingleRequest(url, id){

  }
  PostRequest(url, data){
    this.GetHeaders();
    const response = this.http.post(this.apiUrl + url, data, { headers: this.headers });
    return response;
  }
  GetWebDetail(url){
    this.GetHeaders();
    const response = this.http.get(url, { headers: this.headers });
    return response;
  }
  PostEditRequest(url, id, datatosend){

  }
  PostDeleteRequest(url, id){

  }
  //?fromDate={fromDate}&toDate={toDate}
  GetByDateRequest(url, fromdate, todate){
    this.GetHeaders();
    const response = this.http.get(this.apiUrl + url + '?fromDate=' + fromdate + '&toDate=' + todate, { headers: this.headers });
    return response;
  }
  GetByDateAndIdRequest(url, id, fromdate, todate){

  }
}
