import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'attendance',
    loadChildren: () => import('./pages/attendance/attendance.module').then( m => m.AttendancePageModule)
  },
  {
    path: 'punchinout',
    loadChildren: () => import('./pages/punchinout/punchinout.module').then( m => m.PunchinoutPageModule)
  },
  {
    path: 'helpdesk',
    loadChildren: () => import('./pages/helpdesk/helpdesk.module').then( m => m.HelpdeskPageModule)
  },
  {
    path: 'device-validation',
    loadChildren: () => import('./pages/device-validation/device-validation.module').then( m => m.DeviceValidationPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modal/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./pages/download/download.module').then( m => m.DownloadPageModule)
  },
  {
    path: 'leave',
    loadChildren: () => import('./pages/leave/leave.module').then( m => m.LeavePageModule)
  },
  {
    path: 'leavelist',
    loadChildren: () => import('./pages/leavelist/leavelist.module').then( m => m.LeavelistPageModule)
  },
  {
    path: 'attendanceregularization',
    loadChildren: () => import('./pages/attendanceregularization/attendanceregularization.module').then( m => m.AttendanceregularizationPageModule)
  },
  {
    path: 'attreglist',
    loadChildren: () => import('./pages/attreglist/attreglist.module').then( m => m.AttreglistPageModule)
  },
  {
    path: 'salary',
    loadChildren: () => import('./pages/salary/salary.module').then( m => m.SalaryPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
